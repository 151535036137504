<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/routineTask' }">外勤计划</el-breadcrumb-item>
          <el-breadcrumb-item>新增规则</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <!-- 基本信息 -->
          <div class="form-title">基本信息<span style="color: red"> *</span></div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="规则名称" prop="taskName">
                <el-input v-model="form.taskName" placeholder="请输入规则名称" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="规则描述" prop="taskDesc">
                <el-input v-model="form.taskDesc" placeholder="请输入规则描述" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="日期类型" prop="dateType">
                <el-radio-group v-model="form.dateType">
                  <el-radio-button :label="0">月</el-radio-button>
                  <el-radio-button :label="1">季</el-radio-button>
                  <el-radio-button :label="2">年</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否重复生成规则" prop="repeat" label-width="150px">
                <el-radio-group v-model="form.repeat">
                  <el-radio :label="0">是</el-radio>
                  <el-radio :label="1">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 区域信息配置 -->
          <div class="config-title">
            <div class="form-title">
              区域信息<span style="color: red"> *</span>
            </div>
            <div>
              <el-button @click="addRegion" type="primary" size="small">添加区域</el-button>
            </div>
          </div>
          <el-table :data="selectRegionData" style="width: 100%">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="regionName" label="所属区域"></el-table-column>
            <el-table-column prop="name" label="人员名称"></el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <div>
                  <span @click="dltRegion(scope.row)" style="color:red;cursor:pointer;">删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/routineTask')">取消</el-button>
            <el-button type="primary" size="small" @click="onSubmit">提交</el-button>
          </div>
        </el-form>
      </div>
      
      <!-- 区域侧滑 -->
      <el-drawer
        title="添加区域"
        :with-header="false"
        :visible.sync="regionDrawer"
        direction="rtl"
        size="800px">
        <div class="drawer-title">添加区域</div>
        <div class="drawer-content table-tree">
          <div class="tree">
            <div class="tree-title">所属区域</div>
            <el-tree
              :data="regionTreeList"
              node-key="id"
              @node-click="regionClick"
              :props="regionProps"
              :default-checked-keys="[1]"
              highlight-current
              :current-node-key="regionId"
              ref="patrolTree">
              <span class="custom-tree-node" slot-scope="{ node }">
                <span style="margin-right: 40px">{{ node.label }}</span>
              </span>
            </el-tree>
          </div>
          <div class="table">
            <el-table :data="tableDataCp" style="width: 500px" height="700" v-loading="regionLoading" element-loading-spinner="el-icon-loading">
              <el-table-column label="勾选" width="70">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.checked" @change="checkeChange(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="人员名称"></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="regionCancel">取消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="regionDrawerOK"
            class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import layTime from '@/components/layTime'

export default {
  components: {
    layTime
  },
  data() {
    return {
      loading: false,
      form: {
        dateType: 0,
        repeat: 0
      },
      rules: {
        taskName:[{ required: true, message: '必填项', trigger: 'blur' }],
        taskDesc:[{ required: true, message: '必填项', trigger: 'blur' }],
        dateType:[{ required: true, message: '必填项', trigger: 'change' }],
        repeat:[{ required: true, message: '必填项', trigger: 'change' }],
      },
      value: [],
      staffList: [],
      drawer: false,
      treeData: [],
      tableData: [],

      regionLoading:false,
      regionDrawer: false,
      regionId: 0,
      regionTreeList: [],
      tableDataCp:[],
      tableSelectList: [],
      selectRegionData: [],
      regionProps: {
        children: 'childTree',
        label: 'name'
      },

      checkedStaffList: [],
      staffListID: [],
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d < n;
        }
      },
    }
  },
  methods: {
    // 添加区域
    addRegion(){
      this.regionDrawer = true
      this.tableDataCp.forEach((v)=>{ 
        if (this.selectRegionData.find(item => item.id == v.id)) {
          v.checked = true
        }else {
          v.checked = false
        }
      })
    },
    // 点击区域获取对应人员信息
    regionClick(data,node){
      this.regionId = data.id
      if(this.tableSelectList.length==0){
        this.tableDataCp = []
        this.tableSelectList = []
        this.selectRegionData = []
        this.getRegionList(data.id, data.name)
      }else {
        if(this.tableSelectList[0].regionId==data.id) {
          this.$refs.patrolTree.setCurrentKey(data.id)
        }else{
          node.isCurrent = false
          this.$refs.patrolTree.setCurrentKey(this.tableSelectList[0].regionId)
          this.$message.error("不能跨区域选择人员信息！");
        }
      }
    },
    getRegionList(id, name){
      this.regionLoading = true
      this.$ajax.post("staffByRegion", {
        regionId: id
      }).then((res) => {
        res.data.forEach(v => {
          v.checked = false
          v.regionId = id
          v.regionName = name
          this.tableDataCp.push(v)
        })
        this.regionLoading = false
      }).catch(err=>{
        this.regionLoading = false
      })
    },
    // 勾选人员
    checkeChange(row) {
      if (row.checked) {
        // 勾选
        this.tableSelectList.push(row)
      } else {
        this.tableSelectList.forEach((item, index) => {
          if (item.id == row.id) {
            this.tableSelectList.splice(index, 1)
          }
        })
      }
    },
    regionCancel() {
      this.regionDrawer = false
    },
    // 选择人员点击确定
    regionDrawerOK(){
      this.regionDrawer = false
      this.selectRegionData = []
      this.tableSelectList.forEach(v=>{
        this.selectRegionData.push(v)
      })
    },
    // 删除人员信息
    dltRegion(row){
      this.selectRegionData.forEach((item, index) => {
        if (item.id == row.id) {
          this.selectRegionData.splice(index, 1)
        }
      })
      this.tableSelectList.forEach((v,k) => {
        if (v.id == row.id) {
          this.tableSelectList.splice(k, 1)
        }
      })
    },
    // 获取区域对应的人员列表
    loadList() {
      this.$ajax.post("regionQuery").then((res) => {
        this.regionTreeList = res.data;
        this.regionClick(res.data[0])
        this.regionId = res.data[0].id
      });
    },
    dateSecond(str) {
      let strArr = str.split(':')
      let second =  (strArr[0] * 3600) + (strArr[1] * 60) + Number(strArr[2])
      return second
    },
    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push({
              id: item.id,
              name: item.name,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
    // 提交数据
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let form = {
            cycleType: this.form.dateType,
            fieldPlanDescribe: this.form.taskDesc,
            fieldPlanName: this.form.taskName,
            regionId: this.regionId,
            repeat: this.form.repeat,
            staffIds: []
          }

          if(this.selectRegionData.length == 0) {
            this.$message.error("区域信息不能为空！");
            return
          }
          this.selectRegionData.forEach((item)=>{
            form.staffIds.push(item.id)
          })

          // console.log(form)
          // return
          this.loading = true
          this.$ajax.post("routineCreate", form).then((res) => {
            this.loading = false
            this.$message.success("成功");
            this.$router.push("/routineTask");
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    },
  },
  mounted() {
    this.loadList();
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.config-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 110px)';
  margin-top: 20px;
  .tree {
    width: 220px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.staffBox {
  cursor: pointer;
  width: 325px;
  background: #fff;
  margin-right: 20px;
  height: 40px;
  border-radius: 4px;
  padding: 0 4px;
  border: 1px solid #DCDFE6;
  transition: all .3s ease;
}
.staffBox:hover {
  border: 1px solid #afaaaa;
}
.checkbox {
  margin-bottom: 20px;
  padding-left: 40px;
}
/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #409eff;
  color: white;
}
.drawer-bottom {
  position: static;
  z-index: 1;
  text-align: right;
  padding: 10px 20px 10px 0;
}
/deep/.el-radio-button__inner {
  padding: 12px 48px;
}
</style>
<style lang="less">
.el-drawer.rtl {
  overflow: scroll;
}
</style>